

export const AuthToken = "Auth_Token"

export const BaseUrl = " https://api.newsunityjewellers.in";
export const AdminLoginUrl = `${BaseUrl}/api/admin/login`;
export const AddPricenUrl = `${BaseUrl}/api/product/update-price`;
export const adminDashboardUrl = `${BaseUrl}/api/admin/dashboard`;
export const sendOtpUrl = `${BaseUrl}/api/admin/send-otp?`; 
export const verifyOtpUrl = `${BaseUrl}/api/admin/verify-otp`; 
export const setPasswordUrl = `${BaseUrl}/api/admin/reset-password`; 
export const ordersUrl = `${BaseUrl}/api/admin/get-all-orders?`;
export const productsUrl = `${BaseUrl}/api/product/get-all-product`;
export const toggleAdminUrl = `${BaseUrl}/api/admin/toggle-admin/`;
export const deleteProductsUrl = `${BaseUrl}/api/product/delete-product/`;
export const getAdminsUrl = `${BaseUrl}/api/admin/get-all-admin?`;
export const AdminRegisterUrl = `${BaseUrl}/api/admin/register`;
export const singleProductsUrl = `${BaseUrl}/api/product/get-product/`;
export const toggleOrdersUrl = `${BaseUrl}/api/admin/toggle-order/`; //put/api/admin/toggle-order/:id
export const orderDetailsUrl = `${BaseUrl}/api/admin/orders-details/`; //post 
export const addCategoryUrl = `${BaseUrl}/api/category/add-category`;
export const getCategoryUrl = `${BaseUrl}/api/category/get-all-category`;
export const categorydeleteUrl = `${BaseUrl}/api/category/delete-category/`;

export const updateProductsUrl = `${BaseUrl}/api/product/update-product/`; //put id
export const addProductsUrl = `${BaseUrl}/api/product/add-product`; //post 
