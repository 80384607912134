import './CardBox.scss';
import { FcBusinessman } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FcNews } from "react-icons/fc";
import { IoMdSync } from "react-icons/io";
import { FcCurrencyExchange } from "react-icons/fc";



function CardBox({cardData, rates}) {
    return (
        <div className="cardBox">
            <div className="cardItem">
                <FcCurrencyExchange className='icons'/>
                <div className="textBox">
                    <h3 className="title">Today Rates:</h3>
                    <h3 className="tags">G-22k: {rates?.gold_22_k_price}, G-24k: {rates?.gold_24_k_price}, S-1kg: {rates?.silver_1_kg}<IoMdSync className='miniIcon'/></h3>
                </div>
            </div>
            <div className="cardItem">
                <FcBullish className='icons'/>
                <div className="textBox">
                    <h3 className="title">Total Order:</h3>
                    <h3 className="tags">{cardData?.totalOrders}<IoMdSync className='miniIcon'/></h3>
                </div>
            </div>
            <div className="cardItem">
                <FcNews className='icons'/>
                <div className="textBox">
                    <h3 className="title">Total Product:</h3>
                    <h3 className="tags">{cardData?.totalProducts}<IoMdSync className='miniIcon'/></h3>
                </div>
            </div>
            <div className="cardItem">
                <FcBusinessman className='icons'/>
                <div className="textBox">
                    <h3 className="title">Total Admin:</h3>
                    <h3 className="tags">{cardData?.totalAdmins}<IoMdSync className='miniIcon'/></h3>
                </div>
            </div>
        </div>
    )
}

export default CardBox