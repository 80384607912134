import "./Admins.scss";
import { useState, useEffect } from "react";
import Loder from "../../Components/Loder/Loder";
import PageHeader from "../../Components/PageHead/PageHeader";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Switch from "@mui/material/Switch";
import {
  getAdmins,
  getAdminToken,
  toggleAdminProfile,
} from "../../Firebase/APIS";
const label = { inputProps: { "aria-label": "Color switch demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Admins() {
  const [adminsData, setAdminsData] = useState([]);
  const [page, setPage] = useState(1);

  async function getAdminsData() {
    const result = await getAdmins(getAdminToken());
    if (result?.success) {
      setAdminsData(result.admins);
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const hendelToggle = (id, is_active) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are Switch this Admin!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Switch it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          id: id,
          is_active: !is_active,
        };
        const result = await toggleAdminProfile(getAdminToken(), data);
        if (result?.success) {
          Swal.fire({
            title: "Switched!",
            text: result.message,
            icon: "success",
          });
          getAdminsData();
        }
      }
    });
  };

  useEffect(() => {
    getAdminsData();
  }, [page]);

  return (
    <div className="adminsComponents">
      <div className="adminsContainer">
        <PageHeader page={"Admins"} />
        <div className="adminTableContainer">
          {adminsData?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Mobile</StyledTableCell>
                    <StyledTableCell align="right">Email</StyledTableCell>
                    <StyledTableCell align="right">
                      Created Date
                    </StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminsData?.length > 0 &&
                    adminsData?.map((items, index) => (
                      <StyledTableRow key={index} className="item-row">
                        <StyledTableCell scope="row">
                          {items.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {items.mobile}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {items.email}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {
                            moment(items.createdAt)
                              .format("MMMM Do YYYY, h:mm:ss a")
                              ?.split(",")[0]
                          }
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          onClick={() =>
                            hendelToggle(
                              items.id,
                              items.is_active
                            )
                          }
                        >
                          <Switch
                            {...label}
                            checked={items.is_active}
                            color="success"
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>

              <div
                style={{
                  padding: "0.625rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={
                      adminsData.total_page >= 1 ? adminsData.total_page : page
                    }
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </Stack>
              </div>
            </TableContainer>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default Admins;
