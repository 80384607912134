import "./OrderDetails.scss";
import PageHeader from "../../Components/PageHead/PageHeader";
import { useEffect, useState } from "react";
import Loder from "../../Components/Loder/Loder";
import { headerConfig } from "../../Config/Config";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { orderDetailsUrl, toggleOrdersUrl } from "../../API/API";
import moment from "moment";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function OrderDetails() {
  // const params = useParams();
  const { state } = useLocation();
  const [orderDetails, setOrderDetails] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState(state.item);
  // console.log(orderDetailsData);





  function getorderDetails() {
    // const id = params.id;
    let itemDetails = state;
    // console.log("itemDetails:",state.item);
    const config = headerConfig();
    axios
      .get(`${orderDetailsUrl}${itemDetails.item._id}`, config)
      .then((res) => {
        // console.log("orderDetails_res", res.data);
        setOrderDetails(res.data);
      })
      .catch((err) => {
        console.log("Order Details Err :", err);
      });
  }
  useEffect(() => {
    getorderDetails();
  }, []);

  const statusHandler = (id, status) => {
    // console.log( "id and status " , id, status)
    const config = headerConfig();
    const data = {
      id: id,
      onboard: status === "false" ? "true" : "false",
    };
    axios
      .put(`${toggleOrdersUrl}${id}`, data, config)
      .then((res) => {
        Swal.fire({
          title: "Good job!",
          text: "You clicked the button!",
          icon: "success",
        });
        getorderDetails();
        // console.log(res);
      })
      .catch((err) => {
        Swal.fire({
          title: "Opps...Sorry!",
          text: err,
          icon: "error",
        });
        console.log("Satus Toggle err ", err);
      });
  };
  return (
    <div className="orderDetailsComponents">
      <div className="orderDetailsContainer">
        <PageHeader page={"Order Details"} />
        <div className="orderContainer">
          {orderDetails ? (
            <div className="tableContainer">
              {orderDetailsData?.user ? (
                <TableContainer sx={{ width: "35%" }} component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Client Details:</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Name : {orderDetailsData.user.name}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Mobile : {orderDetailsData.user.mobile}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Email : {orderDetailsData.user.email}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Address : {orderDetailsData.user.address}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Booking Date :{" "}
                          {
                            moment(orderDetailsData?.user?.createdAt)
                              .format("MMMM Do YYYY, h:mm:ss a")
                              ?.split(",")[0]
                          }
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Status :{" "}
                          {orderDetails?.data?.onboard === false ? (
                            <Button
                              onClick={() =>
                                statusHandler(
                                  orderDetails.data._id,
                                  orderDetails.data.onboard
                                )
                              }
                              variant="contained"
                              color="error"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              Pending
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                statusHandler(
                                  orderDetails.data._id,
                                  orderDetails.data.onboard
                                )
                              }
                              variant="contained"
                              color="success"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              Resolved
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <h3>Client Data Not Found!</h3>
              )}

              {orderDetailsData?.product ? (
                <TableContainer sx={{ width: "35%" }} component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Product Details:</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Name : {orderDetailsData?.product?.name}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Weight : {orderDetailsData?.product?.weight}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Category : {orderDetailsData?.product?.category}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Slug : {orderDetailsData?.product?.slug}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Description : {orderDetailsData?.product?.description}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Order Date :{" "}
                          {orderDetailsData?.user
                            ? moment(orderDetailsData?.user?.createdAt)
                                .format("MMMM Do YYYY, h:mm:ss a")
                                ?.split(",")[0]
                            : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <h3>Product Data Not Found!</h3>
              )}

              <div className="imgBox">
                {orderDetails ? (
                  <img
                    src={orderDetailsData?.product?.image?.location}
                    alt="image"
                    className="productimg"
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
