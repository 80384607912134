import "./PageHeader.scss";
import { FaDashcube } from "react-icons/fa6";
import { useState, useEffect } from "react";

function PageHeader(props) {
    const [date, setDate] = useState();
    const [clock, setClock] = useState();

    function timer() {
        // Getting current time and date
        let time = new Date();
        let day = time.getDate();
        let month = time.getMonth() + 1;
        let year = time.getFullYear();
        let hour = time.getHours();
        let min = time.getMinutes();
        let sec = time.getSeconds();
        let am_pm = "AM";

        if (hour >= 12) {
            if (hour > 12) hour -= 12;
            am_pm = "PM";
        } else if (hour === 0) {
            hour = 12;
            am_pm = "AM";
        }

        hour = hour < 10 ? "0" + hour : hour;
        min = min < 10 ? "0" + min : min;
        sec = sec < 10 ? "0" + sec : sec;
        let currentTime = hour + ":" + min + ":" + sec + am_pm;
        let curretDate = day + "/" + month + "/" + year;
        setClock(currentTime);
        setDate(curretDate)
    }

    useEffect(() => {
        setInterval(() => {
            timer();
        }, 1000)
    }, []);

    return (
        <div className="pageheaderComponent">
            <div className="leftBox">
                <FaDashcube className='dash-icon' />
                <h3 className="dash-title">{props.page}</h3>
            </div>
            <div className="rightBox">
                <div className="item-box">
                    <p className="title">Date: {date}</p>
                    <p className="title">Time: {clock}</p>
                </div>
            </div>
        </div>
    )
}

export default PageHeader