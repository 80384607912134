import "./AppBar.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../Assates/Logo/logo.png";
import { NabItems } from "../../Data/NavItem";
import Swal from "sweetalert2";
import { GlobalStorage } from "../../App";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { removeAdminToken } from "../../Firebase/APIS";

function AppBar() {
  const expand = "md";
  const navigate = useNavigate();
  const { setIsAuth } = useContext(GlobalStorage);

  const logout_Handler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want be logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await removeAdminToken();
        if (result.success) {
          setIsAuth(false);
          navigate("/login");
          Swal.fire({
            title: "Logout!",
            text: "You are Logout.",
            icon: "success",
          });
        }
      }
    });
  };

  return (
    <Navbar
      key={expand}
      expand={expand}
      className={`"bg-body-tertiary mb-3 navbar`}
    >
      <Container fluid className="box-container">
        <Navbar.Brand href="/" className="brand">
          <img src={logo} alt="logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          className="menu-btn"
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          className="mobile-navigation"
        >
          <Offcanvas.Header closeButton className="mobile-nav">
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-${expand}`}
              className="nav-head-box"
            >
              <img src={logo} alt="logo" className="logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 items-box">
              {NabItems.map((item, index) => (
                <Link
                  to={item.link}
                  className="href-item"
                  key={index}
                  id={item.name}
                >
                  {item.name}
                </Link>
              ))}
            </Nav>
            <button className="add-btn" onClick={logout_Handler}>
              Logout
            </button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default AppBar;
