import "./Login.scss";
import { useState, useContext, useEffect } from "react";
import { GlobalStorage } from "../../App";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import logimage from "../../Assates/Images/landingpage.png";
import logo from "../../Assates/Logo/logo.png";
import { useNavigate } from "react-router-dom";
import { loginAdmin, getAdminToken, setAdminToken } from "../../Firebase/APIS";

const schema = yup
  .object({
    email: yup.string().required("Enter Valid Email"),
    password: yup.string().required("Enter Valid Password"),
  })
  .required();

function Login() {
  const navigate = useNavigate();
  const { setIsAuth } = useContext(GlobalStorage);
  const [ckeckbox, setCheckbox] = useState(false);
  const { register, handleSubmit } = useForm({ resolver: yupResolver(schema) });

  //LOGIN CHECKING====================================
  useEffect(() => {
    if (getAdminToken()) {
      navigate("/");
    }
  }, []);

  //LOGIN=============================================
  const onSubmit = async (data) => {
    const result = await loginAdmin(data);
    if(result.success){
      await setAdminToken(ckeckbox, result.token);
      setIsAuth(result.token);
      navigate("/");
      Swal.fire({
        title: "Good job!",
        text: result.message,
        icon: "success",
      });
    }else{
      Swal.fire({
        title: "Opps...Sorry!",
        text: result.message,
        icon: "error",
      });
    }
  };

  //SEND OTP==========================================
  const forgotPassword = async () => {
    const { value: email } = await Swal.fire({
      title: "Enter Register Email",
      input: "email",
      inputLabel: "We'll send OTP on your email address.",
      inputPlaceholder: "Enter your email address",
      showCancelButton: true,
    });
    if (email) {
      console.log("Email sent with password: ", email)
    }
  };

  return (
    <div className="login-component">
      <div className="login-image-container">
        <img
          src={logimage}
          alt="sunity jewellery's home page"
          className="login-img"
        />
      </div>
      <div className="login-right-container">
        <form onSubmit={handleSubmit(onSubmit)} className="form-box">
          <div className="brand-box">
            <img src={logo} alt="new sunity jeweller's" className="logo" />
            <div className="text-box">
              <h3 className="title">Login</h3>
              <p className="para">
                Please login if are you a admin for admin panel.
              </p>
            </div>
          </div>

          <div className="inputbox">
            <label htmlFor="email" className="label">
              Email ID
            </label>
            <input
              {...register("email")}
              placeholder="Enter Email Address"
              className="input"
              type="email"
              required
            />
          </div>

          <div className="inputbox">
            <label htmlFor="email" className="label">
              Password
            </label>
            <input
              {...register("password")}
              placeholder="Enter Password"
              className="input"
              type="password"
              required
            />
          </div>

          <div className="bottom-box">
            <label htmlFor="ckeckbox">
              <input type="checkbox" onChange={(e) => setCheckbox(e.target.value)} />
              Remamber Me
            </label>

            <p className="forgot-btn" onClick={forgotPassword}>
              Forgot Password?
            </p>
          </div>

          <div className="buttom-box">
            <input type="submit" className="sunbit-btn" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
