import { getDatabase, ref, get, set, push } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { app } from "./firebase";
import { v4 as uuidv4 } from "uuid";
function generateId() {
  return uuidv4();
}
function currentTimestamp() {
  return Date.now();
}

export async function registerAdmin(adminToken, userData) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");

  try {
    const snapshot = await get(adminsRef);

    if (snapshot.exists()) {
      const users = snapshot.val();

      const existingAdminToken = Object.values(users).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }
      const existingUser = Object.values(users).find(
        (user) => user.email === userData.email
      );
      if (existingUser) {
        return {
          success: false,
          message: "Email already exists.",
        };
      }
    }

    const newUser = {
      id: generateId(),
      name: userData.name,
      email: userData.email,
      mobile: userData.mobile,
      password: userData.password,
      is_active: userData.is_active,
      timestamp: currentTimestamp(),
    };
    if (snapshot.exists()) {
      const admin = snapshot.val();
      const adminArray = Array.isArray(admin) ? admin : Object.values(admin);
      adminArray.push(newUser);
      await set(adminsRef, adminArray);
    } else {
      const adminArray = [newUser];
      await set(adminsRef, adminArray);
    }
    return { success: true, message: "Registered successfully" };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function loginAdmin(userData) {
  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  try {
    const snapshot = await get(adminsRef);
    if (snapshot.exists()) {
      const admins = snapshot.val();
      const existingUser = Object.values(admins).find(
        (admin) => admin.email === userData.email
      );
      if (!existingUser) {
        return {
          success: false,
          message: "Email does not exists.",
        };
      }
      if (existingUser.password !== userData.password) {
        return {
          success: false,
          message: "Password does not match.",
        };
      }
      if (existingUser.is_active === false) {
        return {
          success: false,
          message: "Your account is deactive.",
        };
      }
      return {
        success: true,
        message: "Logged in successfully",
        token: existingUser.id,
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getAdmins(adminToken) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");

  try {
    const snapshot = await get(adminsRef);
    if (snapshot.exists()) {
      const admins = snapshot.val();

      const existingAdminToken = Object.values(admins).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }
      return {
        success: true,
        message: "Fetched admins successfully",
        admins: admins,
      };
    }
  } catch (error) {
    console.log("get admins error :", error);
  }
}

export async function toggleAdminProfile(adminToken, userData) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  try {
    const snapshot = await get(adminsRef);
    if (!snapshot.exists()) {
      return {
        success: false,
        message: "No admin data found",
      };
    }
    const users = snapshot.val();
    const existingAdminToken = Object.values(users).find(
      (admin) => admin.id === adminToken
    );
    if (!existingAdminToken) {
      return {
        success: false,
        message: "Admin token is invalid",
      };
    }
    const existingUserKey = Object.keys(users).find(
      (key) => users[key].id === userData.id
    );

    if (!existingUserKey) {
      return {
        success: false,
        message: "Admin not found",
      };
    }

    users[existingUserKey].is_active = userData.is_active;
    await set(adminsRef, users);

    return {
      success: true,
      message: `Admin activation toggled successfully`,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function addProductCategory(adminToken, data) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };

  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const categoryRef = ref(database, "sunity-jewellers/categorys");

  try {
    const snapshot = await get(adminsRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingAdminToken = Object.values(users).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }

      const categorySnapshot = await get(categoryRef);
      let categoryArray = [];
      if (categorySnapshot.exists()) {
        const categories = categorySnapshot.val();
        const existingCategory = Object.values(categories).find(
          (catItem) => catItem.name === data.name
        );
        if (existingCategory) {
          return {
            success: false,
            message: "Category already exists.",
          };
        }
        categoryArray = Array.isArray(categories)
          ? categories
          : Object.values(categories);
      }

      const newCategory = {
        id: generateId(),
        name: data.name,
        timestamp: currentTimestamp(),
      };
      categoryArray.push(newCategory);
      await set(categoryRef, categoryArray);
      return { success: true, message: "Category added successfully" };
    } else {
      return {
        success: false,
        message: "No admin data found",
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getCategory() {
  const database = getDatabase(app);
  const categoryRef = ref(database, "sunity-jewellers/categorys");
  try {
    const categorySnapshot = await get(categoryRef);
    if (categorySnapshot.exists()) {
      const categories = categorySnapshot.val();
      return { success: true, categories: categories };
    } else {
      return { success: false, message: "No categories found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function deleteCategoryItem(adminToken, id) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  if (!id) return { success: false, message: "Category id is required" };

  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const categoryRef = ref(database, "sunity-jewellers/categorys");

  try {
    const snapshot = await get(adminsRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingAdminToken = Object.values(users).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }

      const categorySnapshot = await get(categoryRef);
      if (categorySnapshot.exists()) {
        const categories = categorySnapshot.val();
        const categoryIndex = Object.keys(categories).find(
          (key) => categories[key].id === id
        );
        if (!categoryIndex) {
          return { success: false, message: "Category not found" };
        }

        delete categories[categoryIndex];

        await set(categoryRef, categories);
        return { success: true, message: "Category deleted successfully" };
      } else {
        return { success: false, message: "No categories found" };
      }
    } else {
      return {
        success: false,
        message: "No admin data found",
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function addPrices(adminToken, data) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };

  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const pricesRef = ref(database, "sunity-jewellers/prices");

  try {
    const adminSnapshot = await get(adminsRef);
    if (adminSnapshot.exists()) {
      const users = adminSnapshot.val();
      const existingAdminToken = Object.values(users).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }

      const pricesSnapshot = await get(pricesRef);
      let pricesData = {};

      if (pricesSnapshot.exists()) {
        pricesData = pricesSnapshot.val();
        // Assuming pricesData is an object, update with new data
        Object.assign(pricesData, data);
      } else {
        pricesData = data;
      }

      await set(pricesRef, pricesData);

      return { success: true, message: "Prices updated successfully" };
    } else {
      return {
        success: false,
        message: "No admin data found",
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getPrices() {
  const database = getDatabase(app);
  const pricesRef = ref(database, "sunity-jewellers/prices");

  try {
    const pricesSnapshot = await get(pricesRef);
    if (pricesSnapshot.exists()) {
      const pricesData = pricesSnapshot.val();
      return { success: true, prices: pricesData };
    } else {
      return { success: false, message: "No prices found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function addProduct(adminToken, data) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };

  const database = getDatabase(app);
  const storage = getStorage(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const productsRef = ref(database, "sunity-jewellers/products");

  try {
    const adminSnapshot = await get(adminsRef);
    if (adminSnapshot.exists()) {
      const users = adminSnapshot.val();
      const existingAdminToken = Object.values(users).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }

      const productsSnapshot = await get(productsRef);
      let productsArray = [];
      if (productsSnapshot.exists()) {
        const productsObj = productsSnapshot.val();
        productsArray = Object.values(productsObj);
        const existingProduct = productsArray.find(
          (product) => product.name === data.name
        );
        if (existingProduct) {
          return { success: false, message: "Product name already exists" };
        }
      }

      // Upload image to Firebase Storage
      const imageRef = storageRef(
        storage,
        `sunity-jewellers/products/images/${data.name}${data.productImage.name}}`
      );
      const snapshot = await uploadBytes(imageRef, data.productImage);
      const imageUrl = await getDownloadURL(snapshot.ref);

      const newProduct = {
        id: generateId(),
        name: data.name,
        image: imageUrl,
        weight: data.weight,
        category: data.category,
        description: data.description,
        timestamp: currentTimestamp(),
      };

      productsArray.push(newProduct);
      const updatedProductsObj = productsArray.reduce((obj, item, index) => {
        obj[index] = item;
        return obj;
      }, {});

      await set(productsRef, updatedProductsObj);

      return { success: true, message: "Product added successfully" };
    } else {
      return {
        success: false,
        message: "No admin data found",
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getProduct() {
  const database = getDatabase(app);
  const productsRef = ref(database, "sunity-jewellers/products");
  try {
    const productsSnapshot = await get(productsRef);
    if (productsSnapshot.exists()) {
      const products = productsSnapshot.val();
      return { success: true, products: products };
    } else {
      return { success: false, message: "No categories found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getSingleProduct(productId) {
  const database = getDatabase(app);
  const productsRef = ref(database, "sunity-jewellers/products");

  try {
    const productsSnapshot = await get(productsRef);
    if (productsSnapshot.exists()) {
      const productsObj = productsSnapshot.val();
      const productsArray = Object.values(productsObj);
      const product = productsArray.find(product => product.id === productId);

      if (product) {
        return { success: true, product };
      } else {
        return { success: false, message: "Product not found" };
      }
    } else {
      return { success: false, message: "No products found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function deleteProduct(adminToken, productId) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };

  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const productsRef = ref(database, "sunity-jewellers/products");

  try {
    const adminSnapshot = await get(adminsRef);
    if (adminSnapshot.exists()) {
      const users = adminSnapshot.val();
      const existingAdminToken = Object.values(users).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }

      const productsSnapshot = await get(productsRef);
      if (productsSnapshot.exists()) {
        let productsArray = Object.values(productsSnapshot.val());
        const productIndex = productsArray.findIndex(
          (product) => product.id === productId
        );

        if (productIndex === -1) {
          return { success: false, message: "Product ID not found" };
        }

        productsArray.splice(productIndex, 1);

        // Convert the array back to an object using index as the key
        const updatedProductsObj = productsArray.reduce((obj, item, index) => {
          obj[index] = item;
          return obj;
        }, {});

        // Update the products reference in the database
        await set(productsRef, updatedProductsObj);

        return { success: true, message: "Product deleted successfully" };
      } else {
        return { success: false, message: "No products found" };
      }
    } else {
      return { success: false, message: "No admin data found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getDashboardData(adminToken) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  const database = getDatabase(app);

  const adminsRef = ref(database, "sunity-jewellers/admins");
  const ordersRef = ref(database, "sunity-jewellers/orders");
  const productsRef = ref(database, "sunity-jewellers/products");
  let totalAdmins, totalOrders, totalProducts, adminData;
  try {
    const adminSnapshot = await get(adminsRef);
    if (adminSnapshot.exists()) {
      const admins = adminSnapshot.val();
      const existingAdminToken = Object.values(admins).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }
      adminData = existingAdminToken;
      totalAdmins = Object.values(admins).length;

      const productsSnapshot = await get(productsRef);
      if (productsSnapshot.exists()) {
        const products = productsSnapshot.val();
        totalProducts = Object.values(products).length;
      } else {
        totalProducts = 0;
      }

      const ordersSnapshot = await get(ordersRef);
      if (ordersSnapshot.exists()) {
        const orders = ordersSnapshot.val();
        totalOrders = Object.values(orders).length;
      } else {
        totalOrders = 0;
      }

      const data = {
        totalAdmins,
        totalProducts,
        totalOrders,
        adminData,
      };
      return { success: true, count: data };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getOrders(adminToken) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const ordersRef = ref(database, "sunity-jewellers/orders");
  try {
    const adminSnapshot = await get(adminsRef);
    if (adminSnapshot.exists()) {
      const admins = adminSnapshot.val();
      const existingAdminToken = Object.values(admins).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }
      const ordersSnapshot = await get(ordersRef);
      if (ordersSnapshot.exists()) {
        const orders = ordersSnapshot.val();
        return { success: true, orders: orders };
      }
      return { success: false, message: "No orders found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getContacts(adminToken) {
  if (!adminToken)
    return { success: false, message: "Admin token is required" };
  const database = getDatabase(app);
  const adminsRef = ref(database, "sunity-jewellers/admins");
  const ordersRef = ref(database, "sunity-jewellers/contacts");
  try {
    const adminSnapshot = await get(adminsRef);
    if (adminSnapshot.exists()) {
      const admins = adminSnapshot.val();
      const existingAdminToken = Object.values(admins).find(
        (admin) => admin.id === adminToken
      );
      if (!existingAdminToken) {
        return {
          success: false,
          message: "Admin token is invalid",
        };
      }
      const ordersSnapshot = await get(ordersRef);
      if (ordersSnapshot.exists()) {
        const orders = ordersSnapshot.val();
        return { success: true, orders: orders };
      }
      return { success: false, message: "No orders found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}
 
const AuthToken = "AuthToken";
export const getAdminToken = () => {
  const localLog = localStorage.getItem(AuthToken);
  const sessionLog = sessionStorage.getItem(AuthToken);
  if (localLog) {
    return localLog;
  } else if (sessionLog) {
    return sessionLog;
  }
};

export const setAdminToken = (ckeckbox, token) => {
  if (ckeckbox) {
    localStorage.setItem(AuthToken, token);
  } else {
    sessionStorage.setItem(AuthToken, token);
  }
};

export const removeAdminToken = async () => {
  localStorage.removeItem(AuthToken);
  sessionStorage.removeItem(AuthToken);
  return { success: true, message: "Logged out successfully" };
};


// ====================user=====================



export async function registerUser(userData) {
  const database = getDatabase(app);
  const usersRef = ref(database, 'sunity-jewellers/users');
  try {
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const users = snapshot.val();

      const existingUserEmail = Object.values(users).find(
        (user) => user.email === userData.email
      );
      const existingUserMobile = Object.values(users).find(
        (user) => user.mobile === userData.mobile
      );
      
      if (existingUserEmail || existingUserMobile) {
        return {
          success: false,
          message: "User already exists",
        };
      }
    }

    const newUser = {
      id: generateId(),
      name: userData.name,
      email: userData.email,
      mobile: userData.mobile,
      password: userData.password,
      address: userData.address,
      is_active: true,
      timestamp: currentTimestamp(),
    };

    if (snapshot.exists()) {
      const users = snapshot.val();
      const usersArray = Array.isArray(users) ? users : Object.values(users);
      usersArray.push(newUser);
      await set(usersRef, usersArray);
    } else {
      const usersArray = [newUser];
      await set(usersRef, usersArray);
    }

    return { success: true, message: "Registered successfully", token: newUser.id };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function loginUser({ email, mobile, password }) {
  const database = getDatabase(app);
  const usersRef = ref(database, 'sunity-jewellers/users');

  try {
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const users = snapshot.val();
      const usersArray = Array.isArray(users) ? users : Object.values(users);

      const existingUser = usersArray.find((user) =>
          (user.email === email || user.mobile === mobile) &&
          user.password === password
      );

      if (existingUser) {
        return {
          success: true,
          message: "Login successful",
          token: existingUser.id,
        };
      } else {
        return {
          success: false,
          message: "Invalid email/mobile or password",
        };
      }
    } else {
      return {
        success: false,
        message: "No users found",
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export default loginUser;






const userData = {
  name: "John Doe",
  email: "johnoe@example.com",
  mobile: "1234567890",
  password: "password123",
  address: "123 Main St",
};  

const logData = {
  email: "johnoe@example.com",
  password: "passwod123",
};  
