import "./Products.scss";
import { useState, useEffect } from "react";
import Loder from "../../Components/Loder/Loder";
import PageHeader from "../../Components/PageHead/PageHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { getProduct, deleteProduct, getAdminToken } from "../../Firebase/APIS";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Products() {
  const [productData, setProductsData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const navigate = useNavigate();

  async function getProductsData() {
    const result = await getProduct();
    if (result?.success) {
      setProductsData(result.products);
    }
  }
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getProductsData();
  }, []);

  const DeleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = getAdminToken();
        const result = await deleteProduct(token, id);
        if (result.success) {
          getProductsData();
          Swal.fire({
            title: "Deleted!",
            text: "Your Data has been deleted.",
            icon: "success",
          });
        } else {
          console.log(result)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  return (
    <div className="productsComponents">
      <div className="productsContainer">
        <PageHeader page={"Products"} />
        <div className="productTableContainer">
          {productData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Category</StyledTableCell>
                    <StyledTableCell align="right">Weight</StyledTableCell>
                    {/* <StyledTableCell align="right">Slug</StyledTableCell> */}
                    <StyledTableCell align="right">
                      Created Date
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">Edit</StyledTableCell> */}
                    <StyledTableCell align="right">Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData?.map((items, index) => (
                    <>
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          scope="row"
                          onClick={() => {
                            navigate("/product/details/", {
                              state: { item: items },
                            });
                          }}
                          className="item-row"
                        >
                          {items?.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {items?.category}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {items?.weight}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {
                            moment(items?.createdAt)
                              .format("MMMM Do YYYY, h:mm:ss a")
                              ?.split(",")[0]
                          }
                        </StyledTableCell>
                        {/* <StyledTableCell
                          align="right"
                          onClick={() =>
                            navigate(`/product/edit-product/id=${items.id}`)
                          }
                        >
                          <IconButton aria-label="edit" size="small">
                            <ModeIcon fontSize="inherit" color="info" />
                          </IconButton>
                        </StyledTableCell> */}
                        <StyledTableCell
                          align="right"
                          onClick={() => DeleteHandler(items.id)}
                        >
                          <IconButton aria-label="delete" size="small">
                            <DeleteIcon fontSize="inherit" color="error" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
                </TableBody>
              </Table>

              <div
                style={{
                  padding: "0.625rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </Stack>
              </div>
            </TableContainer>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default Products;
