import './Register.scss';
import PageHeader from '../../Components/PageHead/PageHeader';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Swal from 'sweetalert2';
import { registerAdmin, getAdminToken } from '../../Firebase/APIS';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

const schema = yup.object({
    name: yup.string().required(),
    mobile: yup.mixed().required(),
    email: yup.string().required(),
    password: yup.string().required(),
    is_active: yup.boolean().required(),
}).required()

function Register() {
    const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(schema) })

    const onSubmit = async (data) => {
        const token = getAdminToken();
        const result = await registerAdmin(token, data);
        if(result.success) {
            Swal.fire({
                title: "Good job!",
                text: result?.message,
                icon: "success"
            });
            reset();
        }else{
            Swal.fire({
                title: "Opps...Sorry!",
                text: result?.message,
                icon: "error"
            });
        }
    }

    return (
        <div className='registerComponents'>
            <div className="registerContainer">
                <PageHeader page={"Add Admin"} />
                <div className="registerFormContainer">
                    <Card style={{ width: "50%" }}>
                        <Card.Header className='text-center' style={{ fontWeight: 600, fontSize: '20px', }}>Add New Admin</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Admin Name" name='name' {...register("name")} required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Mobile Number" name='mobile' {...register("mobile")} required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Email Address" name='email' {...register("email")} required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter New Password" name='password' {...register("password")} required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" id="checkbox">
                                        <Form.Check.Input type="checkbox" isValid name='is_active' {...register("is_active")} />
                                        <Form.Check.Label>Check this button to activate the account!</Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <Button variant="primary" type="submit" style={{ width: "100%" }}>
                                    Submit
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Register