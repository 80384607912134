import "./AddPrice.scss";
import { AddPricenUrl  } from "../../API/API";
import { headerConfig } from "../../Config/Config";
import PageHeader from "../../Components/PageHead/PageHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addPrices, getAdminToken } from "../../Firebase/APIS";
import axios from "axios";
import Swal from "sweetalert2";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const schema = yup
  .object({
    gold_24_k_price: yup.string().required(),
    gold_22_k_price: yup.string().required(),
    silver_1_kg: yup.string().required(),
  })
  .required();

function AddProduct() {
  const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    let Data = {
      "gold_24_k_price" : Number(data.gold_24_k_price),
      "gold_22_k_price" : Number(data.gold_22_k_price),
      "silver_1_kg" : Number(data.silver_1_kg),
    }
    const result = await addPrices(getAdminToken(), Data)
    if(result?.success){
      Swal.fire({
        title: "Good Job!",
        text: result?.message,
        icon: "success",
      });
      reset();
    }else{
      Swal.fire({
        title: "Oops...!",
        text: result?.message,
        icon: "error",
      });
    }
  };

  return (
    <div className="addProductComponents">
      <div className="addProductContainer">
        <PageHeader page={"Add Product"} />
        <div className="productFormContainer">
          <Card style={{ width: "50%" }}>
            <Card.Header
              className="text-center"
              style={{ fontWeight: 600, fontSize: "20px" }}
            >
              Add New Product
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>24K Gold Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Weight"
                    name="weight"
                    {...register("gold_24_k_price")}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>22K Gold Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Weight"
                    name="weight"
                    {...register("gold_22_k_price")}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>silver Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Weight"
                    name="weight"
                    {...register("silver_1_kg")}
                    required
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
