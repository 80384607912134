import React from 'react'
import Categories from '../PageComponent/Categories/Categories'

const CategoriesPage = () => {
  return (
    <div>
      <Categories/>
    </div>
  )
}

export default CategoriesPage
