import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import ProtectRoute from "./ProtectRoute/ProtectRoute";
import AppBar from "./Components/Navbar/AppBar";
import LoginPage from "./Pages/LoginPage";
import DashboardPage from "./Pages/DashboardPage";
import OrdersPage from "./Pages/OrdersPage";
import AdminsPage from "./Pages/AdminsPage";
import ProductsPage from "./Pages/ProductsPage";
import AddProductPage from "./Pages/AddProductPage";
import AddPricePage from "./Pages/AddPricePage";
import RegisterPage from "./Pages/RegisterPage";
import EditProductPage from "./Pages/EditProductPage";
import OrderDetailsPage from "./Pages/OrderDetailsPage";
import PageNotFound from './PageNotFound/PageNotFound';
import CategoriesPage from "./Pages/CategoriesPage";
import ProductDetailsPage from "./Pages/ProductDetailsPage";
import { getAdminToken } from "./Firebase/APIS";

export const GlobalStorage = createContext();

function App() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    setIsAuth(getAdminToken());
  }, []);

  return (
    <div className="App">
      <GlobalStorage.Provider
        value={{
          //sendData
          isAuth,

          //recevedData
          setIsAuth
        }}
      >
        <BrowserRouter>
          {isAuth ? <AppBar /> : null}
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route element={<ProtectRoute />}>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/orders/details" element={<OrderDetailsPage />} />
              <Route path="/products" element={<ProductsPage />} />
              <Route path="/categories" element={<CategoriesPage />} />
              <Route path="/admins" element={<AdminsPage />} />
              <Route path="/product/details/" element={<ProductDetailsPage />} />
              <Route path="/product/add-product" element={<AddProductPage />} />
              <Route path="/product/add-price" element={<AddPricePage />} />
              <Route path="/product/edit-product/:id" element={<EditProductPage />} />
              <Route path="/register" element={<RegisterPage />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </GlobalStorage.Provider>
    </div>
  );
}

export default App;
