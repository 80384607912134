import { Watch } from 'react-loader-spinner'
import "./Loader.scss"
function Loder() {
    return (
       <div className="loader" >
         <Watch
            height="80"
            width="80"
            radius="48"
            color="black"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
       </div>
    )
}

export default Loder