import "./AddProduct.scss";
import { useState, useEffect } from "react";
import { addProductsUrl, getCategoryUrl } from "../../API/API";
import { headerConfig } from "../../Config/Config";
import PageHeader from "../../Components/PageHead/PageHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { addProduct, getAdminToken, getCategory } from "../../Firebase/APIS";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const schema = yup
  .object({
    name: yup.string().required(),
    image: yup.mixed().required(),
    weight: yup.number().required(),
    category: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

function AddProduct() {
  const [categorys, setCategorys] = useState(false);
  const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getCategorys();
  }, []);

  async function getCategorys() {
    const result = await getCategory(getAdminToken());
    if (result?.success) {
      setCategorys(result?.categories);
    }
  }

  const onSubmit =async (data) => {
    const newData = {
      name: data.name,
      weight: data.weight,
      description: data.description,
      category: data.category,
      productImage: data.image[0],
    }
    const result = await addProduct(getAdminToken(), newData)
    if(result?.success){
      Swal.fire({
        title: "Good Job!",
        text: result?.message,
        icon: "success",
      });
      reset();
    } else{
      Swal.fire({
        title: "Opps...Sorry!",
        text: result?.message,
        icon: "error",
      });
    }
  };

  return (
    <div className="addProductComponents">
      <div className="addProductContainer">
        <PageHeader page={"Add Product"} />
        <div className="productFormContainer">
          <Card style={{ width: "50%" }}>
            <Card.Header
              className="text-center"
              style={{ fontWeight: 600, fontSize: "20px" }}
            >
              Add New Product
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Name"
                    name="name"
                    {...register("name")}
                    required
                  />
                  <Form.Text className="text-muted">
                    Enter the name in Camelcase
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Product Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="productImage"
                    {...register("image")}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Product weight</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Weight"
                    name="weight"
                    {...register("weight")}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select Category</Form.Label>
                  <Form.Select
                    size="md"
                    name="category"
                    {...register("category")}
                    required
                  >
                    <option defaultValue="null">Select Category</option>
                    {categorys
                      ? categorys.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Product Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter Product Description"
                    name="description"
                    {...register("description")}
                    required
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
