import "./EditProduct.scss";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import PageHeader from "../../Components/PageHead/PageHeader";
import Loder from "../../Components/Loder/Loder";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getSingleProduct, getCategory } from "../../Firebase/APIS";

function EditProduct() {
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState();
  const [weight, setWeight] = useState();
  const [category, setCategory] = useState();
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState();
  const [categorys, setCategorys] = useState(false);
  const params = useParams();

  useEffect(() => {
    getProduct();
    getCategorys();
  }, []);

  async function getProduct() {
    const result = await getSingleProduct(params.id.split("=")[1]);
    setSpinner(true);
    setName(result.product.name);
    setWeight(result.product.weight);
    setCategory(result.product.category);
    setDescription(result.product.description);
    setImage(result.product.image);
  }

  async function getCategorys() {
    const result = await getCategory();
    setCategorys(result.categories);
  }

  const onSubmit = (data) => {


  };

  return (
    <div className="editProductComponents">
      <div className="editProductContainer">
        <PageHeader page={"Edit Product"} />
        <div className="productFormContainer">
          {spinner ? (
            <Card style={{ width: "50%" }}>
              <Card.Header
                className="text-center"
                style={{ fontWeight: 600, fontSize: "20px" }}
              >
                Edit Product
              </Card.Header>
              <Card.Body>
                <Form onSubmit={onSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Product Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <Form.Text className="text-muted">
                      Enter the name in Camelcase
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product Image</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product weight</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Product Weight"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Category</Form.Label>
                    <Form.Select
                      size="md"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      required
                    >
                      <option value={category}>{category}</option>
                      {categorys.length > 0 
                        ? categorys?.map((item) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter Product Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
