import "./Profile.scss";
import moment from "moment";
import userIcon from "../../Assates/Icons/user.png";
import Swal from "sweetalert2";

function Profile({ adminData }) {
  //SET PASSWORD====================================
  async function passwordSet(newtoken, email) {
    await Swal.fire({
      title: "Set New password",
      input: "password",
      inputLabel: "Enter new password for set password!",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        maxlength: "10",
        autocapitalize: "off",
        autocorrect: "off",
      },
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter new password";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          password: result.value,
        };
      }
    });
  }


  return (
    <div className="prof-box">
      <div className="profileContainer">
        <h3 className="elementTitle">Your Profile</h3>
        <img src={userIcon} alt="userIcon" className="userIcon" />
        <h3 className="title">{adminData?.name}</h3>
        <p className="para">
          createdAt:{" "}
          {
            moment(adminData?.createdAt)
              .format("MMMM Do YYYY, h:mm:ss a")
              ?.split(",")[0]
          }
        </p>
        <div className="divider"></div>
        <h4 className="tatleTag">Mobile: {adminData?.mobile}</h4>
        <h4 className="tatleTag">Email: {adminData?.email}</h4>
        <button className="btn" onClick={passwordSet}>
          Reset Password
        </button>
      </div>
    </div>
  );
}

export default Profile;
