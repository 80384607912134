import "./Categories.scss";
import { useState, useEffect } from "react";
import Loder from "../../Components/Loder/Loder";
import PageHeader from "../../Components/PageHead/PageHeader";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import {
  getCategory,
  getAdminToken,
  deleteCategoryItem,
} from "../../Firebase/APIS";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Categories() {
  const [page, setPage] = useState(2);
  const [categorys, setCategorys] = useState([]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getCategorys();
  }, []);

  async function getCategorys() {
    const result = await getCategory(getAdminToken());
    if (result.success) {
      setCategorys(result.categories);
    }
  }

  const DeleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteCategoryItem(getAdminToken(), id);
        if (result.success) {
            Swal.fire({
                title: "Deleted!",
                text: result.message,
                icon: "success",
            });
            await getCategorys();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log("Delete error :", result);
        }
      }
    });
  };

  return (
    <div className="productsComponents">
      <div className="productsContainer">
        <PageHeader page={"Products"} />
        <div className="productTableContainer">
          {categorys?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categorys?.map((items) => (
                    <StyledTableRow key={items.id}>
                      <StyledTableCell scope="row" className="item-row">
                        {items.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        onClick={() => DeleteHandler(items.id)}
                      >
                        <IconButton aria-label="delete" size="small">
                          <DeleteIcon fontSize="inherit" color="error" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>

              <div
                style={{
                  padding: "0.625rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={categorys?.total_page}
                    page={
                      categorys?.total_page >= 1 ? categorys?.total_page : page
                    }
                    onChange={handleChange}
                    color="primary"
                  />
                </Stack>
              </div>
            </TableContainer>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default Categories;
