import "./ProductDetails.scss";
import PageHeader from "../../Components/PageHead/PageHeader";
import Loder from "../../Components/Loder/Loder";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { useState } from "react";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ProductDetails() {
  // const params = useParams();
  const { state } = useLocation();
  const [orderDetails, setOrderDetails] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState(state.item);
  // console.log(orderDetailsData);

  return (
    <div className="orderDetailsComponents">
      <div className="orderDetailsContainer">
        <PageHeader page={"Order Details"} />
        <div className="orderContainer">
          {orderDetails.lenght !== 0 ? (
            <div className="tableContainer">
              {orderDetails.lenght !== 0 ? (
                <TableContainer sx={{ width: "35%" }} component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Product Details:</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Name : {orderDetailsData?.name}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Weight : {orderDetailsData?.weight}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Category : {orderDetailsData?.category}
                        </StyledTableCell>
                      </StyledTableRow>
                      {/* <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Slug : {orderDetailsData?.slug}
                        </StyledTableCell>
                      </StyledTableRow> */}
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          Description : {orderDetailsData?.description}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow className="item-row">
                        <StyledTableCell scope="row">
                          create Date :{" "}
                          {orderDetailsData.lenght !== 0
                            ? moment(orderDetailsData?.createdAt)
                                .format("MMMM Do YYYY, h:mm:ss a")
                                ?.split(",")[0]
                            : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <h3>Product Data Not Found!</h3>
              )}

              <div className="imgBox">
                {orderDetailsData && <img src={orderDetailsData?.image}  className="productimg" alt=""/>}
              </div>
            </div>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
