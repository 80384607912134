import "./Orders.scss";
import { useState, useEffect } from "react";
import Loder from "../../Components/Loder/Loder";
import PageHeader from "../../Components/PageHead/PageHeader";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getOrders, getAdminToken } from "../../Firebase/APIS";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Orders() {
  const [ordersData, setOrdersData] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  async function getOrdersData() {
    const result = await getOrders(getAdminToken());
    if (result.success){
      setOrdersData(result.orders);
    }
    console.log(result.orders)
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getOrdersData();
  }, []);

  return (
    <div className="ordersComponents">
      <div className="ordersContainer">
        <PageHeader page={"Orders"} />
        <div className="ordertableContainer">
          {ordersData.length !== 0 ? (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Mobile</StyledTableCell>
                    <StyledTableCell align="right">Email</StyledTableCell>
                    <StyledTableCell align="right">
                      Product Name
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Booking Date
                    </StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData?.length > 0 && ordersData.map((items, index) => (
                    <StyledTableRow
                      key={index}
                      className="item-row"
                      // onClick={() =>{ navigate("/orders/details",{ state: { item : items } })}}
                    >
                      <StyledTableCell scope="row">
                        {items?.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {items?.mobile}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {items?.email}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {items?.productId}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {
                          moment(items?.createdAt)
                            .format("MMMM Do YYYY, h:mm:ss a")
                            ?.split(",")[0]
                        }
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {items?.onboard === "false" ? "Panding" : "Resolved"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>

              <div
                style={{
                  padding: "0.625rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={ordersData.total_page}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </Stack>
              </div>
            </TableContainer>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default Orders;
