import React from "react";
import ProductDetailss from "../PageComponent/ProductDetalis/ProductDetalis";
function ProductDetailsPage() {
  return (
    <>
      <ProductDetailss />
    </>
  );
}

export default ProductDetailsPage;
